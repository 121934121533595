import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0,2,6,10,4];

export const dictionary = {
		"/": [~11],
		"/[locale]-[country]": [~13,[2],[3]],
		"/[locale]-[country]/about-us/[...handle]": [~17,[2],[3]],
		"/[locale]-[country]/agb": [~18,[2],[3]],
		"/[locale]-[country]/cart": [19,[2],[3]],
		"/[locale]-[country]/champions": [~20,[2,5],[3]],
		"/[locale]-[country]/checkout": [21,[2],[3]],
		"/[locale]-[country]/collections/[...handle]": [22,[2,6],[3]],
		"/[locale]-[country]/company-history": [~23,[2],[3]],
		"/[locale]-[country]/content/[...handle]": [~24,[2],[3]],
		"/[locale]-[country]/customer": [25,[2,7],[3]],
		"/[locale]-[country]/customer/activate": [26,[2,7],[3]],
		"/[locale]-[country]/customer/address-book": [27,[2,7],[3]],
		"/[locale]-[country]/customer/address-book/edit": [28,[2,7],[3]],
		"/[locale]-[country]/customer/orders": [29,[2,7],[3]],
		"/[locale]-[country]/customer/orders/[number]": [30,[2,7],[3]],
		"/[locale]-[country]/customer/profile": [31,[2,7],[3]],
		"/[locale]-[country]/customer/profile/changeEmail": [32,[2,7],[3]],
		"/[locale]-[country]/customer/profile/changePassword": [33,[2,7],[3]],
		"/[locale]-[country]/customer/profile/edit": [34,[2,7],[3]],
		"/[locale]-[country]/customer/reset": [35,[2,7],[3]],
		"/[locale]-[country]/faq/[[handle]]": [~36,[2],[3]],
		"/[locale]-[country]/imprint": [~37,[2],[3]],
		"/[locale]-[country]/l/[handle]": [~38,[2],[3]],
		"/[locale]-[country]/magazin/[[branch]]": [~40,[2,8],[3]],
		"/[locale]-[country]/magazin/[...handle]": [~39,[2],[3]],
		"/[locale]-[country]/news-press/[[branch]]": [~42,[2,9],[3]],
		"/[locale]-[country]/news-press/[...handle]": [~41,[2],[3]],
		"/[locale]-[country]/order-confirmation/[id]": [43,[2],[3]],
		"/[locale]-[country]/privacy": [~44,[2],[3]],
		"/[locale]-[country]/products/[slug]": [45,[2],[3]],
		"/[locale]-[country]/products/[slug]/[handle]": [~46,[2],[3]],
		"/[locale]-[country]/search": [47,[2,10],[3]],
		"/[locale]-[country]/styleguide": [~48,[2],[3]],
		"/[locale]-[country]/usa": [~49,[2],[3]],
		"/[locale]-[country]/widerrufsrecht": [~50,[2],[3]],
		"/[locale]-[country]/zahlung-versand": [~51,[2],[3]],
		"/[locale]-[country]/[branch=branch]/[slug=slug]": [15,[2],[3]],
		"/[locale]-[country]/[branch=branch]/[slug=slug]/[handle]": [~16,[2],[3]],
		"/[locale]-[country]/[...handle]": [14,[2,4],[3]],
		"/[...rest]": [12]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';